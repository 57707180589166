import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import querystring from "querystring";

import distiAuth from "disti-auth.js";

// react component for creating dynamic tables
import ReactTable from "react-table-6";
 
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "components/Card/CardHeader.js";

import Datetime from "react-datetime"

import moment from "moment-timezone"

import DateFnsUtils from '@date-io/moment'; // choose your lib
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';


import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

//let testingOffset = 0;

var q = {};
try {
 q = querystring.parse(window.location.search.substring(1)); // Skip the "?"
}
catch(e)
{}

export default function ({ classData, onSelected, countryData, regionToName }) {
	
    const classes = useStyles();
    //const [open, setOpen] = React.useState(openArg);
    
    const [pendingSelection, setPendingSelection] = React.useState(null);
 
 
    const timeOfDayIsOkay = (dailyStartTime, adjustedEndTime) => {
        if (dailyStartTime == adjustedEndTime)
        {
            // Same time means all 24 hours, not a 0 second lesson
            return true;
        }
        
        let timeOfDay = nowTime - moment.unix(nowTime).utc().startOf('day').unix();
        if (timeOfDay < dailyStartTime)
        {
            // Give it a chance to fit
            timeOfDay += 60 * 60 * 24;
        }
        
        return dailyStartTime <= timeOfDay && timeOfDay <= adjustedEndTime;
    }
    const timeShowCell = ({value, column: { cellHelper }, row}) => {       
        
        const valid = timeOfDayIsOkay(row.dailyStartTime, row.adjustedEndTime);
        
        //console.log("isStartTime: "+cellHelper.isStartTime+ " is valid: "+valid)
        return (<div style={ valid ? {} : {backgroundColor: "#ff9999"} }>            
            {moment.unix(moment.unix(nowTime).utc().startOf('day').unix() + value).format("HH:mm")}
        </div>)        
    }
    const [nowTime, setNowTime] = React.useState( moment().unix() );
 
    const adjustedEndTime = (startTime, endTime) =>    
    {
        //console.log("startTime(hrs): "+startTime/60/60+" endTime(hrs): "+endTime/60/60);
        if (startTime > endTime)
        {
            // We must be doing a zero crossing
            const secondsInADay = 60*60*24;
            endTime += secondsInADay;
        }
        return endTime;
    }
   
    React.useEffect(()=>{
        if (q.classId && classData)
        {
            const preselectedClass = classData.find(item=>item.pk == q.classId)        
            if (preselectedClass && classIsOkayToLaunch(preselectedClass))
            {                
                setPendingSelection(preselectedClass)
            }
        }
    },[classData, q.classId])

    React.useEffect(()=>{
        if (q.classId && pendingSelection && pendingSelection.pk == q.classId)
        {
            // Do it.
            onSelected(pendingSelection)
        }
    },[(pendingSelection ? pendingSelection.pk : null), q.classId])
    
    const rowIsOkayToLaunch = (index) =>{
        return classIsOkayToLaunch( classData[index] )
    }
    const classIsOkayToLaunch = (item) =>
    {
        let dayEpoc = moment().utc().startOf('day').unix();
        
        if (false)//!timeOfDayIsOkay(item.dailyStartTime, adjustedEndTime(item.dailyStartTime, item.dailyEndTime)))
        {
            alert("This class is not available at this time of day.");
            return false;
        }
        
        if (item.region != distiAuth.runningFromRegion)
        {
            let inRegionName = regionToName ? regionToName[item.region] : item.region;
            let fromRegionName = regionToName ? regionToName[distiAuth.runningFromRegion] : distiAuth.runningFromRegion
            
            const answer = window.confirm("This class is in region '"+inRegionName+"' and you are running from '"+fromRegionName+"'.  Would you like to switch to '"+inRegionName+"'?  Note that interactivity and quality may be significantly degraded.");
            if (answer)
            {
                window.location.href = window.location.href.replace(distiAuth.runningFromRegion+".", item.region+".");
            }            
            return false;
        }
        
        return true;
    }
    React.useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
            setNowTime( moment().unix() );
        }, 30000);

        /* For debugging 
            let dayEpoc = moment().utc().startOf('day').unix();
            setNowTime( dayEpoc + testingOffset );
            testingOffset += 15 * 60;
            testingOffset = testingOffset % (60 *60 *24);
        }, 500);
        */
        
        
        return () => clearInterval(interval);
    });
    
    return (   
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

<div>

            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="primary"
                  >
                    <h4 className={classes.cardTitle} style={{textAlign: "center"}}>Select class to join from the { regionToName ? regionToName[distiAuth.runningFromRegion] : distiAuth.runningFromRegion } region</h4>
                  </CardHeader>                
                  <CardBody>
                  {!classData?(<h1>No classes assigned.</h1>):(<>
                  <div>Current time is: {moment.unix(nowTime).format("HH:mm")}</div>
                    <ReactTable
                      data={classData.map(item => { 
                                item.adjustedEndTime = adjustedEndTime(item.dailyStartTime, item.dailyEndTime) ;
                                return item;
                      })}                      
                      getProps={() => {return {id: 'pk'}}}
                      getTrProps={(state, rowInfo, column, instance) => {
                          let rval = {};
                          if (pendingSelection && rowInfo.original.pk === pendingSelection.pk)
                          {
                            rval.style = 
                            {
                                background: "lightgreen",
                                borderStyle: "inset"
                            } 
                          }                      
                            
                          rval.onClick= e => {
                            console.log('A row was clicked! '+JSON.stringify(rowInfo.original));
                            
                            if (!rowIsOkayToLaunch(rowInfo.index))
                            {
                                return
                            }
                            if (pendingSelection == rowInfo.original)
                            {
                                setPendingSelection(null);
                            }
                            else
                            {
                                setPendingSelection(rowInfo.original);
                            }
                        }
                        return rval;
                      }}                      
                      filterable
                      columns={[
                        {
                          Header: "Region",
                          accessor: "regionName"
                        },
                        { Header: "Class Description",
                          accessor: "description",
                        },
                        {
                          Header: "Start Time (Local)",
                          accessor: "dailyStartTime",
                          cellHelper: {isStartTime: true},
                          Cell: timeShowCell
                        },
                        {
                          Header: "End Time (Local)",
                          accessor: "adjustedEndTime",
                          cellHelper: {isStartTime: false},
                          Cell: timeShowCell
                        }
                      ]}
                      defaultSorted={[
                        {
                          id: "region",
                          desc: false
                        },
                        {
                          id: "description",
                          desc: false
                        }
                      ]}			  
                      pageSize={ classData ? classData.length : 0 }
                      showPaginationTop={false}
                      showPaginationBottom={false}
                      className="-highlight"
                    />
                  <Button 
                    style={{float:"right"}}
                    disabled = {!pendingSelection}
                    onClick={()=>{onSelected(pendingSelection)}} color="primary">
                    Let's Go!
                  </Button>
                  </>)}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>

          </div>
    </MuiPickersUtilsProvider>
          
  );
}

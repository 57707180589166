import React from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardFooter from 'components/Card/CardFooter.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import LoginPage from 'views/VirtualClassroom/LoginPage.js';
import NewPasswordPage from 'views/VirtualClassroom/NewPasswordPage.js';
import AppStreamView from 'views/VirtualClassroom/AppStreamView.js';
import ClassSelector from 'views/VirtualClassroom/ClassSelector.js';

import Snackbar from 'components/Snackbar/Snackbar.js';
import SnackbarContent from 'components/Snackbar/SnackbarContent.js';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {
  PrivacyStatement,
  currentStatementId,
} from 'views/VirtualClassroom/PrivacyStatement.js';

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js';
import querystring from 'querystring';
import distiAuth from 'disti-auth.js';
import axios from 'axios';

import MuiAlert from '@material-ui/lab/Alert';
import StudentImageUploader from './StudentImageUploader';
import { Tenant } from '../../aws-exports.json';

//import { ApolloClient, InMemoryCache } from '@apollo/client';
//import AWSAppSyncClient from 'aws-appsync'

//import { ApolloProvider } from '@apollo/react-hooks';
//import { ApolloLink } from 'apollo-link';
//import { createAuthLink } from 'aws-appsync-auth-link';
//import { createHttpLink } from 'apollo-link-http';
//import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
//import { AppSyncConfig } from '.aws-exports';
//import ApolloClient from 'apollo-client';
//import { InMemoryCache } from "apollo-cache-inmemory";

//import { gql } from '@apollo/client';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

distiAuth.init({ userType: 'STUDENT' });

const useStyles = makeStyles(styles);

const currentAgreementId = currentStatementId;

const previewPhase3 = true; //window.location.search.includes("phase3=55")

const WrongScaleWarning = (params) => {
  const [scaleIsWrong, setScaleIsWrong] = React.useState(null);
  const scaleCb = React.useCallback(() => {
    const isWrong =
      document.body.style.zoom === undefined &&
      (window.devicePixelRatio > 1.05 || window.devicePixelRatio < 0.85);

    let message = isWrong
      ? `Please ${
          window.devicePixelRatio < 1 ? 'increase' : 'decrease'
        } browser zoom level to for correct quality`
      : null;

    setScaleIsWrong(message);
  });
  React.useEffect(() => {
    window.addEventListener('resize', scaleCb);
    return () => {
      window.removeEventListener('resize', scaleCb);
    };
  }, []);
  React.useEffect(() => {
    scaleCb();
  }, [window.devicePixelRatio]);

  return (
    <Snackbar
      place="tc"
      color="warning"
      message={
        <>
          <span
            style={{ display: 'inline-block', width: '30em', color: 'black' }}
          >
            {scaleIsWrong}
            <IconButton
              style={{ float: 'right' }}
              size="small"
              aria-label="close"
              color="black"
              onClick={() => setScaleIsWrong(null)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </>
      }
      open={!!scaleIsWrong}
    ></Snackbar>
  );
};
export default function(props) {
  const { ...rest } = props;
  // TODO: I don't beleive prop is working.

  var q = {};
  try {
    q = querystring.parse(window.location.search.substring(1)); // Skip the "?"
  } catch (e) {}

  const resetNonce = q.nonce;

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [newPasswordMode, setNewPasswordMode] = React.useState(
    !!q.nonce && !!q.email
  );
  const [streamingURL, setStreamingURL] = React.useState('');
  const [selectedClass, setSelectedClass] = React.useState(null);
  const [classData, setClassData] = React.useState(null);
  const [username, setUsername] = React.useState(q.email || '');
  const [loginHelp, setLoginHelp] = React.useState(true);
  const [loginFailMessage, setLoginFailMessage] = React.useState('');
  const [originCountryData, setOriginCountryData] = React.useState({});
  const [regionToName, setRegionToName] = React.useState(null);
  const [acceptedAgreementId, setAcceptedAgreementId] = React.useState(-99);
  //  const apolloClientRef = React.useRef(null)

  const shouldShowAgreementDialog = () => {
    return acceptedAgreementId != currentAgreementId;
  };
  const recordAgreementAcceptance = () => {
    distiAuth.recordAgreementAcceptance(
      currentAgreementId,
      () => {
        console.log('Problem recording acceptance');
      },
      () => {
        setAcceptedAgreementId(currentAgreementId);
      }
    );
  };

  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden');
  setTimeout(function() {
    setCardAnimation('');
  }, 700);

  const handleClassSelected = (sc) => {
    setSelectedClass(sc);

      distiAuth.selectedClass = sc.pk
      
      if (sc && sc.pk && sc.description)
      {                  
        distiAuth.getStreamingURL({
            region: distiAuth.runningFromRegion,
            classId: sc.pk,
            cc: originCountryData ? originCountryData.countryCode : "--",
            reportGroup: "results"
        },
        (e) => {
          console.log('Problem getting StreamingURL: ' + e);
        },
        (s) => {
          setStreamingURL(s);
        }
      );
    }
  };

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: `https://student.${Tenant}.disti.training/get_country_data`,
    })
      .then((response) => {
        console.log('Got country data: ' + JSON.stringify(response));
        setOriginCountryData(response.data);
      })
      .catch((err) => {
        console.log('Problem getting country data: ' + err);
      });
  }, []);

  const doNewPassword = async (u, p) => {
    await distiAuth.applyNewPassword(
      u,
      p,
      resetNonce,
      (e) => {
        // Failure
        distiAuth.clearHashedPW();
        console.log(
          'Got exception from apply_new_password: ' + e.response.status
        );
        window.alert(
          'Unable to apply new password.  Try reset password again?'
        );
        setLoggedIn(false);
        window.location.replace('/student/index.html'); //reload();
      },
      () => {
        // Success
        window.location.replace('/student/index.html');
      }
    );
  };
  const doTheLogin = async (u, p) => {
    setUsername(u);
    distiAuth.storeHashedPW(u, p);
    tryTheLogin();
  };
  const doResetPassword = async (username) => {
    distiAuth.requestPasswordReset(
      username,
      (e) => {
        /*failure*/
        console.log('Problem requesting password reset: ' + e);
      },
      () => {
        /*success*/
        window.setTimeout(() => {
          alert('Check your email for the reset link.');
          handleLogout();
        }, 0);
      }
    );
  };

  const LoggedInContent = () => {
    const doWebcam =
      previewPhase3 && Boolean(selectedClass && selectedClass.useWebcam);
    const [appstreamConnected, setAppStreamConnected] = React.useState(false);
    const onConnected = React.useCallback(() => {
      setAppStreamConnected(true);
    }, [setAppStreamConnected]);
    const onDisconnected = React.useCallback(() => {
      setAppStreamConnected(false);
    }, [setAppStreamConnected]);

    const classRegion = selectedClass
      ? selectedClass.region || 'us-east-1'
      : null;
    const showChat = selectedClass && !Boolean(selectedClass.hideChat);

    return (
      <>
        {streamingURL ? (
          [
            doWebcam && appstreamConnected ? (
              <StudentImageUploader
                keepUniqueImages={selectedClass.captureVideo}
                key="0"
              />
            ) : null,
            <AppStreamView
              key="1"
              streamingURL={streamingURL}
              classRegion={classRegion}
              showChat={showChat}
              onConnected={onConnected}
              onDisconnected={onDisconnected}
            />,
          ]
        ) : (
          <>
            <WrongScaleWarning />

            <Dialog
              open={shouldShowAgreementDialog()}
              fullWidth={false}
              maxWidth={'md'}
              aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">Privacy Policy</DialogTitle>
              <DialogContent>
                <PrivacyStatement />
                <Button
                  style={{ width: '50px' }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    recordAgreementAcceptance();
                  }}
                >
                  Accept
                </Button>
              </DialogContent>
            </Dialog>

            <ClassSelector
              regionToName={regionToName}
              classData={classData}
              countryData={originCountryData}
              onSelected={(c) => classSelected(c)}
              onCanceled={() =>
                console.log('Cancel requested, but what does it mean?')
              }
            />
          </>
        )}
      </>
    );
  };

  const tryTheLogin = async () => {
    try {
      await distiAuth.getAvailableClasses(
        {
          region: distiAuth.runningFromRegion,
        },
        () => {
          console.log('FAIL');

          setLoginFailMessage('Login failed.  Try again.');
          setTimeout(function() {
            setLoginFailMessage('');
          }, 3000);
        },
        (val) => {
          console.log('got class data: ' + JSON.stringify(val));

          setClassData(val.classes);
          setRegionToName(val.regionToName);

          if (distiAuth.getUserData().acceptedAgreementId) {
            setAcceptedAgreementId(distiAuth.getUserData().acceptedAgreementId);
          }

          setLoggedIn(true);
          //setStreamingURL(val);
        }
      );
    } catch (e) {
      console.log('Login failed: ' + e);
    }
  };

  const handleLogout = () => {
    distiAuth.clearHashedPW();
    setLoggedIn(false);
    window.location.replace(`https://student.${Tenant}.disti.training`); //reload();
  };
  const classes = useStyles();
  if (newPasswordMode) {
    // TODO: Decode props.location.search
    // to come up with email and nonce
    return (
      <NewPasswordPage
        email={q.email}
        onApply={(u, p) => doNewPassword(u, p)}
      />
    );
  }
  const classSelected = (c) => {
    console.log('Got classSelected: ' + JSON.stringify(c));
    handleClassSelected(c);
  };
  const loginMessage = (
    <p
      style={{
        margin: '1em',
        textAlign: 'center',
        fontSize: '28pt',
        fontFamily: 'Roboto',
        fontWeight: 'bold',
      }}
    >
      Virtual Classroom
    </p>
  );
  const chooseLessonMessage = (
    <div style={{ textAlign: 'center' }}>
      <h1>Welcome to the Virtual Classroom</h1> <br />
      <h3>
        {username}
        <br />
      </h3>
    </div>
  );
  const launchingAppStreamMessage = (
    <div style={{ position: 'absolute', top: '10px', textAlign: 'center' }}>
      <h1>Preparing Your Content...</h1>
    </div>
  );
  const $ = window.$;
  return window.location.search == '?privacy' ? (
    <div className={classes.container}>
      <PrivacyStatement />
    </div>
  ) : (
    <div className={classes.container}>
      {/*<Alert severity="error">There is a temporary site-wide licensing issue.  You may be able to log in but are unlikely to be able to launch training.  <br/>This banner will be removed as soon as the problem is resolved.</Alert>*/}

      {!streamingURL
        ? loggedIn
          ? chooseLessonMessage
          : loginMessage
        : launchingAppStreamMessage}

      {!loggedIn ? (
        <>
          <Snackbar
            place="bc"
            color="danger"
            message={loginFailMessage}
            open={!!loginFailMessage}
          />
          <LoginPage
            onApply={(u, p) => doTheLogin(u, p)}
            onResetPassword={(u) => doResetPassword(u)}
          />
        </>
      ) : (
        <LoggedInContent streamingURL={streamingURL} />
      )}
    </div>
  );
}
